import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

domReady(() => {
  const carousels = document.querySelectorAll('.questions-grid__carousel');
  const breakpoint = window.matchMedia('(min-width:960px)');

  if (0 < carousels.length) {
    carousels.forEach((carousel) => {
      let swiper;

      const breakpointChecker = function () {
        // if larger viewport and multi-row layout needed
        if (breakpoint.matches === true) {
          // clean up old instances and inline styles when available
          if (swiper !== undefined) swiper.destroy(true, true);

          // else if a small viewport and single column layout needed
        } else if (breakpoint.matches === false) {
          // fire small viewport version of swiper
          return enableSwiper();
        }
      };

      const enableSwiper = function () {
        swiper = new Swiper(carousel, {
          spaceBetween: 16,
          slidesPerView: 1.1,

          breakpoints: {
            600: {
              slidesPerView: 1.5,
              spaceBetween: 32,
            },
            768: {
              slidesPerView: 1.8,
              spaceBetween: 32,
            },
          },

          pagination: {
            el: carousel.querySelector('.questions-grid__carousel__pagination'),
            clickable: true,
          },
        });
      };

      // keep an eye on viewport size changes
      breakpoint.addListener(breakpointChecker);

      // kickstart
      breakpointChecker();
    });
  }
});
